<template>
  <form @submit.prevent class="autocomplete-box" :class="classInput">
    <div class="autocomplete-box-wrapper" v-click-outside="clickOutside">
      <div class="autocomplete-box-field">
        <input v-model="search" @input="debounceSearch" :placeholder="$t('form.filter-by-company-placeholder')" ref="search-box-input"
          class="form-control form-control-sm autocomplete-box-input" :class="classInput" @keydown="handleKeydownSearch"
          @keydown.esc="resetSearch" />
        <input ref="search-box-input-list" class="autocomplete-box-input-list" @keydown.prevent="handleKeydown" />
        <i v-if="search && search.trim().length >= 3" @click="resetSearch" :title="$t('btn.reset')"
          class="autocomplete-box-reset mdi mdi-close" :class="classInput" />
      </div>
      <div class="autocomplete-box-result" v-if="searched">
        <div v-if="loading">
          <div class="text-center">
            <b-spinner variant="primary" />
          </div>
        </div>
        <div v-else>
          <template v-if="resultsFiltered.length">
            <div class="autocomplete-box-list" ref="search-box-list">

              <div v-for="(item, i) of resultsFiltered" :key="item.id" @click="selectCompany"
                class="autocomplete-box-list-item" :class="{ selected: selected === i }"
                :ref="`search-box-list-item-${i}`" @mouseover="selected = i">
                {{ item.name }}
              </div>

            </div>
          </template>
          <div v-else class="autocomplete-box-list-not-found">
            {{ $t('msg.not-found') }}
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'

export default {
  props: {
    classInput: "",
    value: "",
  },
  data() {
    return {
      loading: false,
      searched: false,
      search: '',
      debounce: null,
      results: [],
      selected: '',
      selectedCompany: null,
    }
  },
  created() {
    if (this.value) {
      this.selectedCompany = this.value
      this.search = this.value?.name
    }
  },
  computed: {
    resultsFiltered() {
      // get unique items by id field
      // let res = [...this.results]
      // return res.filter(
      //   (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      // )

      // maybe sort or what...
      return this.results
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ['searchCompanyList']),
    debounceSearch(event) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.startSearch()
      }, 500)
    },
    async startSearch() {
      // console.log('searching, search: ', this.search)
      // async await, try catch

      if (this.search.trim()?.length >= 3) {
        this.loading = true
        this.searched = true

        try {
          const response = await this.searchCompanyList({ per_page: 100, page: 1, qname: this.search });
          console.log('startSearch, response: ', response)

          this.results = response.data?.list?.list || []
        } catch (error) {
          console.log('startSearch, error: ', error)
        } finally {
          this.loading = false
        }

      }
    },
    resetSearch(focused = true) {
      // console.log('reset')
      this.searched = false
      this.search = ''
      this.selectedCompany = null
      this.$emit('input', this.selectedCompany)
      this.results = []

      if (focused) {
        this.$refs['search-box-input'].focus()
      }
    },
    handleKeydownSearch(e) {
      // if arrow down and has result - focus to result list
      if (e.keyCode === 40 && this.resultsFiltered.length) {
        this.selected = 0

        this.$nextTick(() => {
          this.$refs['search-box-input-list'].focus()
        })

        this.checkSelectedPosition()
      }

      // if arrow up and has result - focus to result list
      if (e.keyCode === 38 && this.resultsFiltered.length) {
        this.selected = this.resultsFiltered.length - 1

        this.$nextTick(() => {
          this.$refs['search-box-input-list'].focus()
        })

        this.checkSelectedPosition()
      }
    },
    handleKeydown(e) {
      // Esc - resetSearch()
      if (e.keyCode == 27) {
        this.resetSearch()
      }

      const length = this.resultsFiltered.length

      if (length) {
        // Up
        if (e.keyCode == 38) {
          if (this.selected !== 0) {
            this.selected -= 1
          } else {
            // this.selected = length - 1
            this.selected = ''
            this.$refs['search-box-input'].focus()
          }
        }

        // Down
        if (e.keyCode == 40) {
          if (this.selected < length - 1) {
            this.selected += 1
          } else {
            // this.selected = 0
            this.selected = ''
            this.$refs['search-box-input'].focus()
          }
        }

        this.checkSelectedPosition()

        // Enter
        if (e.keyCode == 13) {
          this.selectCompany()
        }
      }
    },
    scrollParentToChild(parent, child) {
      const parentRect = parent.getBoundingClientRect()

      const parentViewableArea = {
        height: parent.clientHeight,
        width: parent.clientWidth,
      }

      const childRect = child.getBoundingClientRect()

      const isViewable =
        childRect.top >= parentRect.top &&
        childRect.bottom <= parentRect.top + parentViewableArea.height

      if (!isViewable) {
        const scrollTop = childRect.top - parentRect.top
        const scrollBot = childRect.bottom - parentRect.bottom
        if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
          parent.scrollTop += scrollTop
        } else {
          parent.scrollTop += scrollBot
        }
      }
    },
    checkSelectedPosition() {
      if (this.selected || this.selected === 0) {
        const parent = this.$refs['search-box-list']
        const child = this.$refs[`search-box-list-item-${this.selected}`][0]?.$el

        // console.log('child: ', child)
        if (child) {
          this.scrollParentToChild(parent, child)
        }
      }
    },
    selectCompany() {
      this.selectedCompany = this.resultsFiltered[this.selected]
    },
    clickOutside() {
      // restore name if exist selected company
      if (this.selectedCompany) {
        this.search = this.selectedCompany.name
      } else {
        this.search = ""
      }

      this.searched = false
    },
  },
  watch: {
    search(val) {
      if (!val) {
        this.resetSearch(false)
      }
    },
    selectedCompany(company) {
      if (company) {
        this.search = company.name
        this.$emit('input', company)
      } else {
        this.search = ''
        this.$emit('input', "")
      }

      this.searched = false // hide results list
    },
  },
}
</script>

<style lang="scss" scoped>
// Search-box
.autocomplete-box {
  --color-text: #000;
  --color-primary-light: #8d4bff;

  flex: 1 1 100%;
  position: relative;
  z-index: 10;

  :deep(.el-alert) {
    margin: 10px 0;
  }

  &-wrapper {
    display: flex;
    position: relative;
    z-index: 1;
  }

  &-field {
    flex-grow: 1;
    position: relative;
    z-index: 1;
  }

  &-input-list {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  &-reset {
    display: block;
    position: absolute;
    top: 50%;
    right: 8px;
    width: 30px;
    height: 30px;
    margin: -15px 0 0 0;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    background: transparent;

    &.is-invalid {
      right: 30px;
    }
  }

  &-result {
    background: #ffffff;
    border: 2px solid rgba(33, 43, 54, 0.1);
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.08);
    border-radius: 0;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  &-list {
    overflow: hidden;
    overflow-y: auto;
    max-height: 240px; // 40 * 6
  }

  &-list-not-found {
    text-align: center;
    padding: 10px 16px;
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--color-text);
  }

  &-list-item {
    display: block;
    position: relative;
    padding: 10px 16px;
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: var(--color-text);
    text-decoration: none !important;
    text-align: left;

    &:hover,
    &.selected {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  &-filters {
    padding: 8px 16px;
  }

  &-filters-btn {
    height: 42px;
    margin: 0 4px;
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: -0.5px;

    &:not(:hover) {
      background: none transparent;
      border-color: #DEDEDE;
    }

    path {
      stroke: var(--color-text);
    }

    circle {
      fill: var(--color-text);
    }

    rect {
      fill: #fff;
    }

    &.active,
    &:hover,
    &:focus {
      path {
        stroke: #fff;
      }

      circle {
        fill: #fff;
      }

      rect {
        fill: var(--color-text);
      }
    }
  }
}
</style>
